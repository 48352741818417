
import axios from "axios";
import React from "react";
import { Block } from 'notiflix/build/notiflix-block-aio';
import { Notify } from 'notiflix/build/notiflix-notify-aio';
import { Link } from "react-router-dom";
import moment from "moment";

const url = require('url');

export const BlockPopup = Block;
export const ToastPopup = Notify;



var API_ENDPOINT;
var IMAGE_ENDPOINT;
if (process.env.REACT_APP_ENVIROMENT === "staging") {
  API_ENDPOINT = 'https://app.tanglebuild.com';
  IMAGE_ENDPOINT = 'https://tangle-app.s3.me-south-1.amazonaws.com/';
} else if (process.env.REACT_APP_ENVIROMENT === "production") {
  API_ENDPOINT = 'https://app.tanglebuild.com';
  IMAGE_ENDPOINT = 'https://tangle-app.s3.me-south-1.amazonaws.com/';
} else {
  API_ENDPOINT = 'http://localhost:5000';
  IMAGE_ENDPOINT = 'https://tangle-app.s3.me-south-1.amazonaws.com/';

}
export const IMAGE_URL = IMAGE_ENDPOINT
export const API_URL = API_ENDPOINT;


export const UCASE = ([first, ...rest], locale = navigator.language) =>
  first.toLocaleUpperCase(locale) + rest.join('')



export const STATUS_OPTION = [{ label: "Active", value: "Active" }, { label: "De-Active", value: "De-Active" }]

export const LAYOUT_TYPE_OPTION = [{ label: "Ecommerce", value: "ecommerce" }, { label: "Food", value: "food" }]

export const STORE_STATUS_OPTION = [{ label: "Pending", value: "Pending" }, { label: "Active", value: "Active" }, { label: "De-Active", value: "De-Active" }, { label: "Expired", value: "Expired" }]
export const BOOLEAN_OPTION = [{ label: "Yes", value: "Yes" }, { label: "No", value: "No" }]





export const maxSizeInBytes = 10 * 1024 * 1024; // 10MB maximum file size



export const uploadFileServer = async (data) => {
  try {
    var response = await axios.post(`/upload/s3`, data)
    return response.data.file
  } catch (err) {
    console.log("error", err)
    return null
  }

}


export const fixUrl = (url) => {
  const prefix = 'https://';
  const parts = url.split(prefix);
  if (parts.length >= 3) {
    return prefix + parts[parts.length - 1];
  } else {
    return url;
  }
}
export const googleMapURLRegex = /^https:\/\/maps\.app\.goo\.gl\/[A-Za-z0-9]+$/;

export const getLatLong = async (shortUrl) => {
  try {
    let redirectUrl = shortUrl;
    let maxRedirects = 5; // Set the maximum number of redirects you want to follow
    let redirectsFollowed = 0;
    let response;

    while (redirectsFollowed < maxRedirects) {
      response = await fetch(redirectUrl, {
        method: 'GET',
        redirect: 'manual'
      });

      if (response.status >= 300 && response.status < 400) {
        const location = response.headers.get('location');
        if (location) {
          redirectUrl = location;
          redirectsFollowed++;
        } else {
          throw new Error('Redirect location not found');
        }
      } else {
        break;
      }
    }

    if (!response.ok) {
      throw new Error('Failed to follow redirects');
    }

    const finalUrl = response.url;

    // Parse the final URL to extract lat and long
    const parsedUrl = url.parse(finalUrl, true);
    const query = parsedUrl.query;

    if (query && query.q) {
      // Coordinates are typically in the 'q' parameter
      const coords = query.q.split(',');
      const latitude = parseFloat(coords[0]);
      const longitude = parseFloat(coords[1]);
      return { latitude, longitude };
    } else {
      throw new Error('Coordinates not found in the URL');
    }
  } catch (error) {
    console.error('Error fetching coordinates:', error);
  }
}

export const statusColor = {
  "Pending": "#FFA500",       // Orange
  "Processing": "#0000FF",    // Blue
  "Shipped": "#1E90FF",       // DodgerBlue
  "Out Of Delivery": "#FFD700",// Gold
  "Delivered": "#008000",     // Green
  "Cancelled": "#FF0000",     // Red
  "Returned": "#FF6347",      // Tomato
  "Refunded": "#8B0000"       // DarkRed
}

export const ListItem = ({ label, labelar, value, type }) => {
  var title = localStorage.lang === "ar" ? labelar : label
  var valueRender = <span className="data-value">{value}</span>
  if (!value) {
    valueRender = <span className="data-value">~</span>
  } else {
    if (type == "link") {
      valueRender = <span className="data-value">
        <Link to={IMAGE_URL + value} target="_blank">View</Link>
      </span>

    } else if (type == "externallink") {
      valueRender = <span className="data-value">
        <Link to={value} target="_blank">{value}</Link>
      </span>

    }
    else if (type == "image") {
      valueRender = <span className="data-value">
        <img src={IMAGE_URL + value} style={{ width: 150 }} />
      </span>
    }
    else if (type == "date") {
      valueRender = <span className="data-value">
        <span className="data-value">{moment(value).format("DD-MM-YYYY")}</span>
      </span>
    }
    else if (type == "color") {
      valueRender = <span className="data-value">
        <span className="data-value"><div style={{ width: 40, height: 40, background: value, border: "1px solid red" }}></div></span>
      </span>
    }
  }

  return <div className="data-item">
    <div className="data-col">
      <span className="data-label">{title}</span>
      {valueRender}
    </div>
  </div>
}


export const formatDate = (mongoDate) => {
  const date = new Date(mongoDate);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

export const UCASEWORD = (str) => {
  return str.split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

export const convertToQueryParams = (params) => {
  return Object.keys(params)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(params[key]))
    .join('&');
};