import React from "react"
export const Language = {
    en: {
        welcome: "Welcome",
        logout: "Logout",
        subtitle: "At a glance summary of your account.",
        recentList: "Recent Orders",
        recentPayment: "Recent Payments",
        havetotal: "You have total",
        records: "Records",
        goback: "Go Back",
        exportcsv: "Export CSV",
        view: "View",
        personalinfo: "Personal Information",
        selectuserstatus: "Select User Status",
        fullName: "Full Name",
        email: "Email",
        mobile: "Mobile",
        delete: "Delete",
        filter: "Filter",
        fromDate: "From Date",
        toDate: "To Date",
        viewedit: "View/Edit",
        view: "View",
        edit: "Edit",
        addNew: "Add New",
        saveChanges: "Save Changes",
        fillAll: "Fill all the required Fields",
        submit: "Submit",
        reset: "Reset",
        appName: "Application Name",
        appLogo: "Application Logo",
        favicon: "Favicon",
        adminLogo: "Admin Logo",
        appemail: "Application Email",
        address: "Contact Address",
        instagramLink: "Instagram Link",
        facebookLink: "Facebook Link",
        youtubeLink: "Youtube Link",
        whatsappLink: "Whatsapp Link",
        authTitle: "Auth Title",
        authInfo: "For Mobile App Login Screens",
        authTitle_ar: "Auth Title Arabic",
        authSubTitle: "Auth Subtitle",
        authSubTitle_ar: "Auth Subtitle Arabic",

        privacyLink: "Privacy Policy Link",
        termsLink: "Terms & Condition",
        update: "Update",
        paymentGateway: "Payment GateWay Setup",
        paymentClient: "Client Key",
        paymentSecret: "Client Secret Key",
        paymentMode: "Mode",
        updatePassword: "Update Password",
        newpassword: "New Password",
        newpassword1: "Confirm Password",
        siteSetting: "Site Setting",
        //PROEPRTY INFO
        propertyInfo: "Property Information",
        basics: "Basics",
        listingType: "Listing Type",
        category: "Category",
        propertyTitle: "Property Title",
        propertyLocation: "Property Location",
        country: "Country",
        viewOnMap: "View on Map",
        rentPrice: "Price",
        rentType: "Type",
        featuredAd: "Is Featured Ad ?",
        imageGallery: "Image & Video Gallery",
        propertyFeatures: "Property Features",
        video: "Video",
        images: "Images",
        features: "Features",
        //EVENT INFORMATION
        overview: "Overview",
        teams: "Teams",
        groups: "Groups",
        league: "League Matches",
        sponser: "Sponsers",
        pointTable: "Point Table",
        knockouts: "Knockout Matches",
        eventInfo: "Event Information",
        teams: "Teams",
        yes: "Yes",
        no: "No",
        loadingTxt: "Loading....",
        //Toast Status
        userUpdate: "User Status Updated",
        errorTxt: "Error Occured Try Again",
        recordAdded: "Record Added Successfully",
        recordEdit: "Record Edited Successfully",
        recordDelete: "Record Deleted Successfully",
        propertyStatus: "Property Status Updated",
        notificationText: "Notification Sent Successfully",
        fileUploaded: "File Uploaded Successfully",
        settingUpdate: "Setting Updated Successfully",
        passwordUpdate: "Password Updated Successfully",
        //LOGIN PAGE
        emailAddress: "Email Address",
        password: "Password",
        signIn: "Sign in",
        adminSignIn: "Admin Sign-In",
        accessThe: "Access the",
        accessSub: "panel using your email and passcode.",
        otpverification: "OTP Verification",
        otpSub: "Enter the verification code we sent to",
        verifyOtp: "Verify OTP",
        didntget: "Didn’t get the code ?",
        resend: "Resend",
        otpResend: 'OTP Sent Successfully',
        enterOTP: "Enter the OTP",
        eventStatus: "Event Status Updated",
        //EVENT INFO
        eventInfo: "Event Information",
        eventSubHeading: "Basic information about the selected event.",
        category: "Category",
        eventName: "Event Name",
        location: "Location",
        viewOnMap: "View on Map",
        featuredEvent: "Featured Event",
        showOnSlider: "Show on Home Silder",
        viewBanner: "View Banner",
        description: "Description",
        additionInfo: "Additional Information",
        regPrice: "Registration Price",
        matchType: "Match Type",
        minPlayer: "Minimum Player in a Team",
        maxPlayer: "Maximum Player in a Team",
        maxTeams: "Maximum Teams",
        maxGroups: "Maximum Groups",
        startDate: "Event Start Date",
        registrationOpenDate: "Reg.Start Date",
        registrationCloseDate: "Reg.Close Date",
        date: "Date",
        courtNo: "Court No",
        group: "Group",
        updateCourtAndDate: "Update Date and Court No",
        teams: "Teams",
        mat: "Mat",
        won: "Won",
        lost: "Lost",
        tied: "Tied",
        pts: "Pts",
        round: "Round",
        updateScore: "Update Score",
        teamA: "Team A",
        teamB: "Team B",
        place: "Place",
        action: "Action",
        movetoKnockout: "Move to Knockout",
        beInteger: "Number should be an integer",
        viewplayers: "View Players",
        vieweditplayers: "View/Edit Players",
        selectStatus: "Select Status",
        assignteam: "Assign Team",
        assignplayer: "Assign Player",
        deleteplayer: "Delete Player",
        editteam: "Edit Team",
        assignUpdate: "Team Assigned Successfully",
        announcement: "Announcement",
        title: "Title",
        message: "Message",
        notificationType: "Type",
        dob: "Date of Birth",
        civilID: "Civil ID Front",
        civilIDBack: "Civil ID Back",
        academyGallery: "Academy Gallery",
        status: "Status",
        orderInfo: "Order History",
        bussinessInfo: "Bussiness Detail",
        bankInfo: "Bank Detail",
        storeInfo: "Store Detail",
        planHistoryInfo: "Plan History",
        searchMerchant: "Search Merchant",
        searchUser: "Search User",
        searchby: "Search by name,email",
        searchParentCategory: "Parent Category",
        searchChildCategory: "Child Category",
        searchbytitle: "Search By Title",
        productImage: "Product Images",
        varient: "Product Variant",
        name: "Name",
        size: "Size",
        color: "Color",
        additionalCostPrice: "Extra Cost Price",
        additionalSalePrice: "Extra Sale Price",
        stock: "Stock",
        uploadImage: "Upload Image",
        options: "Options",
        value: "Values",
        choosePlan: "Choose Plan",
        currency: "Currency",
        searchstore: "Search Store",
        searchbystore: "Search By Store Name",
        //Order Detail
        "order": "Order",
        createdAt: "Created At",
        orderTo: "Order To",
        invoice: "Invoice",
        orderID: "Order ID",
        date: "Date",
        status: "Status",
        sNo: "S.No",
        name: "Name",
        price: "Price",
        qty: "Qty",
        amount: "Amount",
        subTotal: "Subtotal",
        deliveryFee: "Delivery Fee",
        grandTotal: "Grand Total",
        merchantCode: "Merchant Code (Payment Gateway)"










    },
    ar: {
        welcome: "مرحباً",
        logout: "تسجيل خروج",
        subtitle: "في لمحة ملخص حسابك.",
        recentList: "القائمة الأخيرة",
        recentPayment: "المدفوعات الأخيرة",
        havetotal: "لديك المجموع",
        records: "السجلات",
        goback: "عُد",
        exportcsv: "تصدير CSV",
        view: "منظر",
        personalinfo: "معلومات شخصية",
        selectuserstatus: "حدد حالة المستخدم",
        fullName: "الاسم الكامل",
        email: "بريد إلكتروني",
        mobile: "متحرك",
        delete: "يمسح",
        filter: "منقي",
        fromDate: "من التاريخ",
        toDate: "ان يذهب في موعد",
        viewedit: "معاينة ما تم تحريره",
        view: "منظر",
        edit: "يحرر",
        addNew: "اضف جديد",
        saveChanges: "حفظ التغييرات",
        fillAll: "املأ جميع الحقول المطلوبة",
        submit: "يُقدِّم",
        reset: "إعادة ضبط",
        appName: "اسم التطبيق",
        appLogo: "شعار التطبيق",
        favicon: "فافيكون",
        adminLogo: "شعار المسؤول",
        appemail: "البريد الإلكتروني للتطبيق",
        address: "عنوان الإتصال",
        instagramLink: "رابط Instagram",
        facebookLink: "رابط الفيسبوك",
        youtubeLink: "رابط يوتيوب",
        whatsappLink: "رابط الواتس اب",
        authTitle: "عنوان المصادقة",
        authInfo: "لشاشات تسجيل الدخول إلى تطبيقات الجوال",
        authTitle_ar: "عنوان العنوان العربي",
        authSubTitle: "الترجمة الفرعية",
        authSubTitle_ar: "الترجمة الفرعية العربية",

        privacyLink: "رابط سياسة الخصوصية",
        termsLink: "الشروط والشرط",
        update: "تحديث",
        paymentGateway: "إعداد بوابة الدفع",
        paymentClient: "مفتاح العميل",
        paymentSecret: "المفتاح السري للعميل",
        paymentMode: "وضع",
        updatePassword: "تطوير كلمة السر",
        newpassword: "كلمة المرور الجديدة",
        newpassword1: "تأكيد كلمة المرور",
        siteSetting: "إعداد الموقع",
        //PROEPRTY INFO
        propertyInfo: "معلومات الملكية",
        basics: "الأساسيات",
        listingType: "نوع القائمة",
        category: "فئة",
        propertyTitle: "عنوان الممتلكات",
        propertyLocation: "موقع العقار",
        country: "دولة",
        viewOnMap: "عرض على الخريطة",
        rentPrice: " سعر",
        rentType: "يكتب",
        featuredAd: "هل الإعلان مميز؟",
        imageGallery: "معرض الصور والفيديو",
        propertyFeatures: "خصائص المنشأة",
        video: "فيديو",
        images: "الصور",
        features: "سمات",
        yes: "نعم",
        no: "لا",
        loadingTxt: "تحميل....",
        userUpdate: "تحديث حالة المستخدم",
        errorTxt: "حدث خطأ حاول مرة أخرى",
        recordAdded: "سجل أضيفت بنجاح",
        recordEdit: "سجل التحرير بنجاح",
        recordDelete: "سجل حذف بنجاح",
        propertyStatus: "تم تحديث حالة الخاصية",
        notificationText: "إرسال الإخطار بنجاح",
        fileUploaded: "تم رفع الملف بنجاح",
        settingUpdate: "ضبط التحديث بنجاح",
        passwordUpdate: "تم تحديث كلمة السر بنجاح",
        //LOGIN PAGE
        emailAddress: "عنوان البريد الإلكتروني",
        password: "كلمة المرور",
        signIn: "تسجيل الدخول",
        adminSignIn: "تسجيل الدخول",
        accessThe: "الوصول إلى",
        accessSub: "لوحة تستخدم بريدك الإلكتروني ورمز المرور.",
        otpverification: "التحقق من OTP",
        otpSub: "أدخل رمز التحقق الذي أرسلناه إليه",
        verifyOtp: "تحقق من OTP",
        didntget: "لم تحصل على الرمز؟",
        resend: "إعادة إرسال",
        otpResend: 'أرسل OTP بنجاح',
        enterOTP: "أدخل OTP",
        eventStatus: "تحديث حالة الحدث",
        //LEFT HEADER
        overview: "ملخص",
        teams: "فرق",
        groups: "مجموعات",
        league: "مباريات الدوري",
        sponser: "الرعاة",
        pointTable: "جدول النقطة",
        knockouts: "مباريات خروج المغلوب",
        eventInfo: "دعوة معلومات",
        teams: "فرق",
        yes: "نعم",
        no: "لا",
        //EVENT INFO BASIC
        eventInfo: "دعوة معلومات",
        eventSubHeading: "المعلومات الأساسية حول الحدث المحدد.",
        category: "فئة",
        eventName: "اسم الحدث",
        location: "موقع",
        viewOnMap: "عرض على الخريطة",
        featuredEvent: "حدث مميز",
        showOnSlider: "عرض على منزلق المنزل",
        viewBanner: "عرض لافتة",
        description: "وصف",
        additionInfo: "معلومات إضافية",
        regPrice: "سعر التسجيل",
        matchType: "نوع مباراة",
        minPlayer: "الحد الأدنى لاعب في فريق",
        maxPlayer: "ماكس لاعب في فريق",
        maxTeams: "الفرق القصوى",
        maxGroups: "المجموعات القصوى",
        startDate: "تاريخ بدء الحدث",
        registrationOpenDate: "تاريخ ريج",
        registrationCloseDate: "تاريخ ريج",
        date: "تاريخ",
        courtNo: "المحكمة لا",
        group: "مجموعة",
        updateCourtAndDate: "تاريخ التحديث والمحكمة لا",
        teams: "فرق",
        mat: "حصيرة",
        won: "فاز",
        lost: "ضائع",
        tied: "ربط",
        pts: "نقاط",
        round: "دائري",
        updateScore: "تحديث درجة",
        teamA: "الفريق أ",
        teamB: "الفريق ب",
        place: "مكان",
        action: "فعل",
        movetoKnockout: "انتقل إلى خروج المغلوب",
        beInteger: "يجب أن يكون الرقم عددًا صحيحًا",
        viewplayers: "عرض اللاعبين",
        vieweditplayers: "عرض/تحرير اللاعبين",
        selectStatus: "حدد الحالة",
        assignteam: "تعيين الفريق",
        assignplayer: "تعيين لاعب",
        deleteplayer: "حذف اللاعب",
        editteam: "تحرير فريق",
        assignUpdate: "تم تعيين الفريق بنجاح",
        announcement: "إعلان",
        title: "عنوان",
        message: "رسالة",
        notificationType: "يكتب",
        dob: "تاريخ الميلاد",
        civilID: "واجهة الهوية المدنية",
        civilIDBack: "هوية مدنية مرة أخرى",
        academyGallery: "معرض الأوسكار",
        status: "حالة",
        orderInfo: "تاريخ الطلب",
        bussinessInfo: "تفاصيل العمل",
        bankInfo: "تفاصيل البنك",
        storeInfo: "تفاصيل المتجر",
        planHistoryInfo: "خطة التاريخ",
        searchMerchant: "البحث التاجر",
        searchUser: "مستخدم البحث",
        searchby: "البحث بالاسم ، البريد الإلكتروني",
        searchParentCategory: "القسم الرئيسي",
        searchbytitle: "البحث عن طريق العنوان",
        productImage: "صور المنتج",
        varient: "متغير المنتج",
        name: "اسم",
        size: "مقاس",
        color: "لون",
        additionalCostPrice: "سعر تكلفة إضافي",
        additionalSalePrice: "سعر بيع إضافي",
        stock: "مخزون",
        uploadImage: "تحميل الصور",
        options: "خيارات",
        value: "قيم",
        choosePlan: "اختر الخطة",
        currency: "عملة",
        searchstore: "متجر البحث",
        searchbystore: "البحث باسم المتجر",
        //Order Detail
        "order": "طلب",
        createdAt: "تم إنشاؤها في",
        orderTo: "إلى",
        invoice: "فاتورة",
        orderID: "معرف الطلب",
        date: "تاريخ",
        status: "حالة",
        sNo: "sNo",
        name: "اسم",
        price: "سعر",
        qty: "كتي",
        amount: "كمية",
        subTotal: "نطاق فرعي",
        deliveryFee: "رسوم التسليم",
        grandTotal: "المجموع الإجمالي",
        merchantCode: "رمز التاجر (بوابة الدفع)"




















    }
}