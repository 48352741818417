import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios';
import { API_URL, IMAGE_URL } from '../actions/constant';
import { geterrors, clearerror } from './errorReducer'
import { Loading } from 'notiflix/build/notiflix-loading-aio';
import { Confirm } from 'notiflix/build/notiflix-confirm-aio';
import { Language } from '../actions/language';




export const counterSlice = createSlice({
    name: 'crud',
    initialState: {
        list: null,
        loading: false,
        add: null,
        edit: null,
        deleteone: null,
        viewone: null,
        reduxname: null,
        setting: {},
        lang: Language["en"],
        parentcategory: [],
        childcategory: [],
        storeoption: []
    },
    reducers: {
        listloading: (state, action) => {
            state.loading = action.payload
            state.list = null
            state.add = {}
            state.edit = {}
            state.deleteone = {}
            state.viewone = null
            state.reduxname = null
        },
        loading: (state, action) => {
            state.loading = true
        },
        stoploading: (state, action) => {
            state.loading = false
        },
        list: (state, action) => {
            state.list = action.payload.data
            state.loading = false
            state.reduxname = action.payload.reduxName

        },
        add: (state, action) => {
            state.add = action.payload
            state.loading = false

        },
        edit: (state, action) => {
            state.edit = action.payload
            state.loading = false

        },
        deleteone: (state, action) => {
            state.deleteone = action.payload
            state.loading = false

        },
        viewone: (state, action) => {
            state.viewone = action.payload
            state.loading = false

        },
        setsetting: (state, action) => {
            state.setting = action.payload
        },
        setmerchant: (state, action) => {
            state.setting.merchantOptions = action.payload
        },
        setlanguage: (state, action) => {
            state.lang = action.payload

        },
        setparentcategory: (state, action) => {
            state.parentcategory = action.payload

        },
        setchildcategory: (state, action) => {
            state.childcategory = action.payload

        },
        setstoreoption: (state, action) => {
            state.storeoption = action.payload

        }
    },
})

export const { list, add, edit, deleteone, viewone, loading, listloading, stoploading, setsetting, setlanguage, setparentcategory, setchildcategory, setstoreoption, setmerchant } = counterSlice.actions

//API CALLS

export const listSetting = (endpoint, params) => dispatch => {
    var pleaseWaitTxt = localStorage.lang === "ar" ? "انتظر من فضلك..." : "Please Wait..."
    Loading.standard(pleaseWaitTxt, { messageColor: "#ff9400", svgColor: "#ff9400", svgSize: '50px', backgroundColor: 'rgba(255,255,255,1)', });

    axios.get(`${API_URL}${"/api/setting/"}${params ? params : "?"}`)
        .then(res => {
            dispatch(setsetting(res.data));
            document.title = res.data.appName;
            var link = document.querySelector("link[rel~='shortcut icon']");
            if (!link) {
                link = document.createElement('link');
                link.rel = 'icon';
                document.head.appendChild(link);
            }
            link.href = IMAGE_URL + res.data.favicon;
            Loading.remove()
        })
        .catch(err => {
            console.log("err", err)
            Loading.remove()

            dispatch(geterrors(err.response.data))
        });
};

export const listData = (endpoint, params, reduxName) => dispatch => {
    dispatch(listloading(true));
    dispatch(clearerror())
    axios.get(`${API_URL}${endpoint}${params ? params : "?"}`)
        .then(res => {
            // dispatch(list(res.data));
            dispatch(list({ data: res.data, reduxName }));

        })
        .catch(err => {
            console.log("err", err)
            dispatch(stoploading())
            dispatch(geterrors(err.response.data))
        });
};

export const addData = (endpoint, data) => dispatch => {
    dispatch(loading(true));
    dispatch(clearerror())

    axios.post(API_URL + endpoint, data)
        .then(res => {
            dispatch(add(res.data));
        })
        .catch(err => {
            console.log("err", err)
            dispatch(stoploading())
            dispatch(geterrors(err.response.data))
        });
};
export const editData = (endpoint, data) => dispatch => {
    dispatch(loading(true));
    dispatch(clearerror())

    axios.post(API_URL + endpoint, data)
        .then(res => {
            dispatch(edit(res.data));
        })
        .catch(err => {
            console.log("err", err)
            dispatch(stoploading())
            dispatch(geterrors(err.response.data))
        });
};

export const deleteData = (endpoint, data) => dispatch => {

    var confirmText = localStorage.lang === "ar" ? "تأكيد" : "Confirm"
    var confirmSubText = localStorage.lang === "ar" ? "هل أنت متأكد أنك تريد الحذف؟" : "Are you sure you want to delete?"
    var currLanguage = localStorage.lang === "ar" ? "ar" : "en"
    Confirm.init({
        rtl: localStorage.lang === "ar"
    })

    Confirm.show(
        confirmText,
        confirmSubText,
        Language[currLanguage].yes,
        Language[currLanguage].no,
        () => {
            dispatch(loading(true));
            dispatch(clearerror())

            axios.post(API_URL + endpoint, data)
                .then(res => {
                    dispatch(deleteone(res.data));
                })
                .catch(err => {
                    console.log("err", err)
                    dispatch(stoploading())
                    dispatch(geterrors(err.response.data))
                });
        },
        () => {

        },
        {
        },
    );

};

export const viewoneData = (endpoint, data) => dispatch => {
    dispatch(loading(true));
    dispatch(clearerror())

    axios.post(API_URL + endpoint, data)
        .then(res => {
            dispatch(viewone(res.data));
        })
        .catch(err => {
            console.log("err", err)
            dispatch(stoploading())
            dispatch(geterrors(err.response.data))
        });
};

export const getDataAPI = async (endpoint) => {

    try {
        let result = await axios.get(`${API_URL}${endpoint}`)
        return result.data;
    } catch (err) {
        console.log("err", err)
        return [];

    }
};

export const postDataAPI = async (endpoint, data) => {

    try {
        let result = await axios.post(API_URL + endpoint, data)
        return result.data;
    } catch (err) {
        return [];

    }
};


export const getParentCategory = (params) => async dispatch => {

    dispatch(setparentcategory([]))


    try {
        let result = await getDataAPI(`/api/category/all${params ? params : "?"}`)
        var temp = result.docs
        var options = temp.map(({ _id, title }) => ({
            value: _id,
            name: `${title}`,
        }))
        console.log("options", options)
        dispatch(setparentcategory(options))
    } catch (err) {
        console.log("err", err)
        return [];

    }
};


export const getStores = (params) => async dispatch => {

    dispatch(setstoreoption([]))


    try {
        let result = await getDataAPI(`/api/store/all${params ? params : "?"}`)
        var temp = result.docs
        var options = temp.map(({ _id, title }) => ({
            value: _id,
            name: `${title}`,
        }))
        dispatch(setstoreoption(options))
    } catch (err) {
        console.log("err", err)
        return [];

    }
};

export const getChildCategory = (params) => async dispatch => {

    dispatch(setchildcategory([]))


    try {
        let result = await getDataAPI(`/api/category/all${params ? params : "?"}`)
        var temp = result.docs
        var options = temp.map(({ _id, title }) => ({
            value: _id,
            name: `${title}`,
        }))
        dispatch(setchildcategory(options))
    } catch (err) {
        console.log("err", err)
        return [];

    }
};

export const getMerchant = (params) => async dispatch => {

    try {
        let result = await getDataAPI(`/api/merchant/option`)
        dispatch(setmerchant(result))
    } catch (err) {
        console.log("err", err)
        return [];

    }
};




export default counterSlice.reducer
